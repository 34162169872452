<template>
  <v-container
    fluid
    fill-height
  >
    <v-menu
      open-on-hover
      bottom
      right
      offset-y
      transition="slide-y-transition"
      style="position: absolute; right: 0; top: 0; padding: 15px;"
    >
      <router-link
        v-ripple
        slot="activator"
        class="toolbar-items"
        to=""
      >
        <v-badge
          color="error"
          overlap
        >
          <span style="text-transform: uppercase; color: rgba(0,0,0,.87);">{{ selectedLocale.locale }}</span>
        </v-badge>
      </router-link>
      <v-card>
        <v-list dense>
          <v-list-tile
            v-for="language in languages"
            :key="language.locale"
            @click="changeLanguage(language)"
          >
            <v-list-tile-title
              v-text="$t(language.name)"
            />
          </v-list-tile>
        </v-list>
      </v-card>
    </v-menu>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            :color="'primary'"
            flat
          >
            <v-toolbar-title style="text-align: center;">
              <img
                src="../../assets/StockManager.png"
                width="50%"
                style="width: 100%; max-width: 200px; padding-top: 5px">
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                :rules="[requiredValue, validateEmail]"
                v-model="email"
                :label="$t('forgotPassword.email')"
                :name="$t('forgotPassword.email')"
                prepend-icon="mdi-email"
                type="text"
                class="required"
                @keyup.enter="onForgotPassword($event)"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <span @click="goBackLogin"><a>{{ $t('common.callBackLogin') }}</a></span>
            <v-btn
              color="success"
              @click="onForgotPassword($event)">{{ $t('forgotPassword.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
export default {
  data: () => ({
    valid: true,
    email: null,
    username: '',
    password: '',
    isLoading: false
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'languages', 'selectedLocale'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    // Require value
    requiredValue (value) {
      if (!functionUtils.isNull(value) && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    // Validate email
    validateEmail (value) {
      if (value) {
        value = functionUtils.removeSpaceInString(this.email.toLowerCase())
      }
      if (functionUtils.validateEmail(value)) {
        return true
      }
      return this.$t('register.emailInvalidate')
    },
    /**
     * Change language
     */
    changeLanguage (language) {
      this.$i18n.locale = language.locale
      this.$store.dispatch('changeLanguage', language)
    },
    // Validate
    validate () {
      if (this.$refs.form.validate()) {
        let filter = {
          email: this.email,
          domainWithBasePath: stringUtils.DOMAIN_BASE_PATH_ADMIN
        }
        this.isLoading = true
        this.SEND_EMAIL_FORGOT_PASSWORD(filter).then(
          function () {
            this.isLoading = false
            this.ON_SHOW_TOAST({
              'message': this.$t('forgotPassword.pleaseCheckEmailToChangePassword'),
              styleType: ToastType.SUCCESS
            })
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                'message': this.$t(errorText),
                'styleType': ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * Go dashboard
     */
    onForgotPassword: function (event) {
      event.preventDefault()
      this.validate()
    },
    goBackLogin: function () {
      this.$router.push({
        path: 'login'
      })
    },
    ...mapActions(['ON_SHOW_TOAST', 'SEND_EMAIL_FORGOT_PASSWORD'])
  }
}
</script>
<style lang="scss" scoped>
.translate-toolbar .translate-toolbar__content{
  justify-content: flex-end !important;
  float: right;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.v-toolbar .v-toolbar__content .v-toolbar__items .toolbar-items:hover {
  background: #e5e5e5;
}
.v-card__actions {
  display: flex;
  justify-content: space-between;
}
</style>
